@import 'styles/globalsVars';

.jobImg {
  width: 100%;
  height: 100%;
  padding-top: 3px;

  & > img {
    vertical-align: top;
    object-fit: contain;
    width: 100%;
    height: auto;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.item {
  cursor: pointer;
  line-height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  .badge-icon {
    position: absolute;
    right: 15px;
    width: 35px;
    height: 35px;
    top: -0px;

    .badge-top {
      position: relative;
      background: #ff8888;
      height: 3px;
      width: 38px;
      margin-bottom: -1px;
      z-index: 1;
    }

    .badge-shadow {
      position: absolute;
      z-index: 1;
      display: block;
      clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0);
      background: rgba(0, 0, 0, 0.5);
      height: 36px;
      width: 38px;
    }

    .badge-star {
      position: absolute;
      z-index: 2;
      display: block;
      font-size: 22px;
      background: $active-red;
      color: #fff;
      clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0);
      padding: 2px 8px 7px 8.5px;
    }
  }

  .youtubeIconWrp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    height: max-content;
    left: calc(50% - 30px);
    top: calc(50% - 50px);
    position: absolute;
  }

  .job-details {
    background: #fff;
    padding: 10px;
    height: auto;

    h3,
    h4 {
      font-family: $arial;
      font-size: 14px;
      text-align: center;
      line-height: 18px;
      text-transform: uppercase;
    }

    h4 {
      font-size: 12px;
      line-height: 15px;
      text-transform: none;
    }
  }

  &:hover {
    .youtubeIcon {
      color: #ff0000;
      cursor: pointer;
    }
  }

  .youtubeIcon {
    font-size: 60px;
    color: #282828;
    opacity: 0.8;
  }
}

.featuredJobs {
  // MAX
  @media only screen and (max-width: 1450px) {
    min-height: 15vw;

    .jobImg {
      min-height: 13vw;
    }
  }

  @media only screen and (max-width: 1024px) {
    min-height: 18vw;

    .jobImg {
      min-height: 18vw;
    }
  }

  @media only screen and (max-width: 640px) {
    min-height: 25vw;

    .jobImg {
      min-height: 25vw;
    }
  }

  @media only screen and (max-width: 479px) {
    min-height: 50vw;

    .jobImg {
      min-height: 50vw;
    }
  }

  //MIN
  @media only screen and (min-width: 1450px) {
    min-height: 11vw;

    .jobImg {
      min-height: 10vw;
    }
  }

  @media only screen and (min-width: 2048px) {
    min-height: 9.2vw;

    .jobImg {
      min-height: 9.2vw;
    }
  }
}
