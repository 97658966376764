
@import 'styles/globalsVars';

.features {
  position: relative;
  width: auto;
  background: $navy-blue;

  .ctaBlock {
    position: absolute;
    height: 100%;
    display: flex;

    .yellowBlock {
      background: $gold;
      color: white;
      width: 560px;
      height: 101%;
      bottom: 0;
      position: absolute;
      z-index: 2;
      overflow: hidden;
      clip-path: polygon(0px 0px, 64% 0, 100% 100%, 0 100%);
      margin: 0;
    }

    .blueBlock {
      background: $navy-blue;
      color: white;
      width: 500px;
      height: 100%;
      position: absolute;
      z-index: 3;
      clip-path: polygon(0px 0px, 60% 0, 100% 100%, 0 100%);
      justify-content: center;

      .container {
        padding-left: 40px;

        h2 {
          color: #fff;
          letter-spacing: 4px;
          font-family: $arial-black;
          font-size: 34px;
          margin-bottom: 0;
        }
      }
    }
  }
  .featuredList {
    height: auto;
    background: #060607;
    display: flex;
    align-items: center;
    padding: 8px 0 12px;

  

  }

  .featuresMobile {
    &__text {
      text-align: center;
      h2 {
        color: #fff;
        letter-spacing: 4px;
        font-weight: 600;
        font-family: Arial, sans-serif;
        font-size: 35px;
        padding-top: 5px;
      }
    }
    &__button {
      display: flex;
      justify-content: flex-end;
      padding: 14px 15px;
      .redArrowWrapper {
        margin: 0;
        .arrowShadow {
          height: 36px;
          width: 254px;
          margin-top: 5px;
          margin-left: 5px;
        }
        .arrowButton {
          width: 264px;
          padding: 5px 15px 5px 8px;
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .cta-block {
      .blueBlock {
        .container {
          h2 {
            font-size: 34px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media screen and (min-width: 993px) {
    .featuresMobile {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {
    .cta-block {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .featuresMobile {
      &__text {
        text-align: center;
        h2 {
          font-size: 30px;
        }
      }
      &__button {
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 425px) {
    .featuresMobile {
      &__text {
        padding-top: 5px;
        text-align: center;
        h2 {
          font-size: 20px;
        }
      }
    }
  }
}

.containerFluid {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}



.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
}

// Home Page Red Arrow Button
.redArrowWrapper {
  margin-top: 10px;

  .arrowShadow {
    clip-path: polygon(95% 0, 100% 50%, 94% 100%, 0 100%, 0 0);
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    width: 324px;
    height: 50px;
    margin-left: 7px;
    margin-top: 7px;
  }
  .arrowButton {
    position: relative;
    width: 340px;
    padding: 8px 0px 8px 0px;
    font-size: 22px;
    letter-spacing: 3px;
    border-radius: 0 0 0 0;
    background: #cf1818;
    border: 1px solid #e01a1a;
    background-image: linear-gradient(#ac1414, rgba(0, 0, 0, 0));
    box-shadow: none;
    color: #fff;
    font-family: "Arial Black", Arial, sans-serif;
    text-transform: uppercase;
    z-index: 10;
    clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 0 0);
    transition: transform 0.1s ease;
    transform: translateY(0px);
    &:hover {
      color: #fff;
      transform: translateY(2px);
    }
    &:focus {
      outline: none;
    }
  }
}