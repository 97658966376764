@import 'styles/globalsVars';

.mainHero {
  width: 100%;
  max-height: 800px;
  background-image: url('../../../public/assets/img/Hero_Background.webp');
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;

  display: flex;
  justify-content: center;
  border-bottom: 5px solid $gold;

  &__inner {
    max-width: 1200px;
    display: flex;
    padding: 0 1.25em;

    .leftCol {
      flex: 1;
      display: flex;
      justify-content: center;
      margin-top: 3.1em;
      .truckerImg {
        max-width: inherit;
        bottom: 0;
        width: auto;
        height: 650px;
        position: relative;
        img {
          height: inherit !important;
        }
      }
    }

    .rightCol {
      flex: 2;
      padding-left: 10em;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;

      .mainTitle {
        position: relative;
        font-family: $arial-black;
        color: #fff;
        font-size: 68px;
        letter-spacing: 2px;
        align-self: center;
        margin-bottom: 18px;
      }
      .secondaryTitle {
        position: relative;
        font-family: $arial;
        font-weight: bold;
        text-transform: uppercase;
        color: #000;
        background-color: rgba(255, 255, 255, 0.7);
        font-size: 22px;
        padding: 7px 8px 4px;
        letter-spacing: 1px;
        align-self: center;
        margin-bottom: 36px;
      }
    }
  }

  p {
    font-family: $arial-black;
    bottom: 2%;
    right: 2%;
    margin: 0;
    font-weight: 400;
    position: absolute;
    font-size: 16px;
    letter-spacing: 1px;
    color: #fff;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
  }
}

// @Media Queries
@media screen and (max-width: 1150px) {
  .mainHero {
    &__inner {
      .rightCol {
        padding-left: 5em;
      }
    }
  }
}
@media screen and (max-width: 1040px) {
  .mainHero {
    &__inner {
      .rightCol {
        padding-left: 2em;
      }
    }
    .greenWrapper {
      .greenButton {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .mainHero {
    &__inner {
      .leftCol {
        .truckerImg {
          height: 500px;
        }
      }
      .rightCol {
        padding-left: 5em;
        .mainTitle {
          font-size: 56px;
        }
        .secondaryTitle {
          font-size: 18px;
        }
      }
    }
    .greenWrapper {
      .greenButton {
        font-size: 16px;
        padding: 2px 10px;
      }
    }
  }
}
@media screen and (max-width: 869px) {
  .mainHero {
    &__inner {
      padding: 0 0.5em;
      .rightCol {
        padding-left: 1em;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .mainHero {
    &__inner {
      padding: 0 1.5em;
      .leftCol {
        .truckerImg {
          height: 450px;
        }
      }
      .rightCol {
        padding-left: 5em;
        .mainTitle {
          font-size: 44px;
        }
        .secondaryTitle {
          font-size: 14px;
          padding: 6px 8px 4px;
        }
      }
    }
    p {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 744px) {
  .mainHero {
    &__inner {
      .rightCol {
        padding-left: 2em;
      }
    }
  }
}
@media screen and (max-width: 696px) {
  .mainHero {
    &__inner {
      padding: 0 0.5em;
      .leftCol {
        .truckerImg {
          height: 300px;
        }
      }
      .rightCol {
        padding-left: 1em;
        .mainTitle {
          font-size: 32px;
          margin-bottom: 14px;
        }
        .secondaryTitle {
          font-size: 10px;
          margin-bottom: 22px;
        }
      }
    }
    .greenWrapper {
      .greenButton {
        font-size: 14px;
        padding: 0 8px;
      }
    }
    p {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 486px) {
  .mainHero {
    &__inner {
      padding: 0 0.5em;
      .leftCol {
        display: none;
      }
      .rightCol {
        padding: 4em 0;
        .mainTitle {
          font-size: 43px;
          margin-bottom: 14px;
        }
        .secondaryTitle {
          font-size: 14px;
          margin-bottom: 22px;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .mainHero {
    &__inner {
      .rightCol {
        .mainTitle {
          font-size: 39px;
        }
        .secondaryTitle {
          font-size: 12px;
        }
      }
    }
  }
}
@media screen and (max-width: 410px) {
  .mainHero {
    &__inner {
      .rightCol {
        .mainTitle {
          font-size: 32px;
        }
        .secondaryTitle {
          font-size: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .mainHero {
    &__container {
      height: 220px;
      .leftCol {
        display: none;
      }
      .rightCol {
        &__wrapper {
          h1 {
            width: 220px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

// Main Green Button
.greenWrapper {
  margin-top: 10px;
  position: relative;

  .greenShadow {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    height: 98%;
    margin-top: 8px;
    width: 96%;
    margin-left: 2%;
    transition: all 0.5s;
    border-radius: 4px;
  }

  .greenButton {
    position: relative;
    padding: 6px 12px;
    letter-spacing: 2px;
    background-image: linear-gradient(#207f0e, #26a20f);
    border: 1px solid $active-green;
    border-radius: 4px;
    color: #fff;
    font-family: $arial;
    font-weight: bold;
    font-size: 25px;
    text-transform: uppercase;
    box-shadow: none;
    z-index: 10;
    transition: all 0.2s ease;
    transition: transform 0.1s ease;
    transform: translateY(0px);
    span {
      font-family: inherit;
    }
    &:hover {
      color: #fff;
      transform: translateY(2px);
    }
    &:focus {
      outline: none;
    }
  }
}
