.clipTop {
  clip-path: polygon(4% 0, 96% 0, 100% 100%, 0 100%);
}

.clipShadow {
  clip-path: polygon(98% 0%, 98% 78%, 50% 100%, 2% 78%, 2% 0%);
}

.clipText {
  clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0);
}

.section {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; /* set to 100% for full overlay width */
    background: rgba(255, 255, 255, 0.2);
    z-index: 1;
  }
}
@media screen and (max-width: 1200px) {
  .redRibbon {
    .text {
      width: 30rem;

      h3 {
        font-size: 1.6rem;
      }
    }
  }
  .testimonyContainer {
    .hoverBox {
      width: 17.5rem;
      height: 16.2rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .redRibbon {
    .text {
      width: 26.8rem;

      h3 {
        font-size: 1.3rem;
      }
    }
  }
  .testimonyContainer {
    .hoverBox {
      width: 17.5rem;
      height: 16.2rem;
    }
  }
  .greenButton {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  .redRibbon {
    .text {
      width: 25rem;

      h3 {
        font-size: 1.2rem;
      }
    }
  }
  .testimonyContainer {
    .hoverBox {
      width: 15.3rem;
      height: 14rem;
      margin: 0.6rem 0.3rem;
      
          .quote {
            font-size: 0.9rem;
          }
          .author {
            font-size: 0.8rem;
          }
        }
  }
  .greenButton {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .testimonyContainer {
    .hoverBox {
      &:nth-child(2) {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 515px) {
  .testimonyContainer {
    flex-direction: column;

    .hoverBox {
      &:nth-child(2) {
        display: flex;
      }

      .top {
         
          .quote {
            font-size: 0.8rem;
          }
          .author {
            font-size: 0.7rem;
          }
        }
      }
    
  }
  @media screen and (max-width: 425px) {
    .redRibbon {
      .text {
        width: 20rem;

        h3 {
          font-size: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .redRibbon {
      .text {
        width: 15rem;

        h3 {
          font-size: 0.9rem;
        }
      }
    }
    .testimonyContainer {
      .hoverBox {
        width: 14rem;
        height: 12.8rem;
      }
    }
  }
}
